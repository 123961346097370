import React from 'react';
import {
  FormControl,
  FormLabel,
  Input
} from "@chakra-ui/react"

export const Login = props => {
  const { email, password, setEmail, setPassword } = props

  const handleOnChange = (name, e) => {
    if (name === 'email') {
      setEmail(e.target.value);
    }
    if (name === 'password') {
      setPassword(e.target.value);
    }
  }

  return (
    <>
      <FormControl>
        <FormLabel>Email</FormLabel>
        <Input placeholder="Email" name="email" value={email} onChange={(e) => handleOnChange('email', e)} />
      </FormControl>

      <FormControl mt={4}>
        <FormLabel>Password</FormLabel>
        <Input placeholder="Password" name="password" value={password} onChange={(e) => handleOnChange('password', e)} type="password" />
      </FormControl>
    </>
  )
};
