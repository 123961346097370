import { useState, useEffect } from 'react'
import constate from 'constate'
import axios from 'axios'
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const useAuth = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [authUser, setAuthUser] = useState(null)
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setAuthUser(cookies.get('authUser'))
    }, [])

    const login = () => {
        setError(null)
        setIsLoading(true)

        return axios({
            url: 'api/user/login',
            data: { email, password },
            method: "POST"
        }).then(response => {
            setIsLoading(false)

            setAuthUser(response.data)
            cookies.set('authUser', response.data, { path: '/' });
            return response.data
        }, handleError)
    }

    const logout = () => {
        cookies.remove('authUser');
        setAuthUser(null)
    }

    const resetLoginForm = () => {
        setError(null)
        setIsLoading(false)
        setPassword('')
        setEmail('')
    }

    function handleError(error) {
        setIsLoading(false)
        setError(error.response.data.message)

        throw error
    }

    return { setEmail, setPassword, email, password, login, authUser, error, isLoading, resetLoginForm, logout}
}

export const [AuthProvider, useAuthContext] = constate(useAuth)