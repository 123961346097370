import { Token } from "./Token";
import { Chain } from './Chain';
import { BigintIsh, Token as sdkToken, TokenAmount as sdkTokenAmount } from "@sushiswap/sdk";
import { ChainExchange } from './ChainExchange';

export interface IPairPricer {
    id: string;
    chain: Chain;
    token0: Token;
    token1: Token;
}

export class PairPricer implements IPairPricer {

    chain: Chain;
    token0: Token;
    token1: Token;
    chainExchange: ChainExchange;

    constructor(chain: Chain, token0: Token, token1: Token, chainExchange: ChainExchange) {
        this.chain = {...chain}
        this.token0 = {...token0}
        this.token1 = {...token1}
        this.chainExchange = {...chainExchange}
    }

    get id(): string {
        return `${this.chain.id}-${this.token0.id}-${this.token1.id}-${this.chainExchange.id}`
    }

    setToken0Amount(amount: BigintIsh) {
        const newSdkToken = new sdkToken(this.chain.id, this.token0.address, this.token0.decimals, this.token0.symbol, this.token0.name)

        this.token0.tokenAmount = new sdkTokenAmount(newSdkToken, amount)

        return this
    }

    setToken1Amount(amount: BigintIsh) {
        const newSdkToken = new sdkToken(this.chain.id, this.token1.address, this.token1.decimals, this.token1.symbol, this.token1.name)

        this.token1.tokenAmount = new sdkTokenAmount(newSdkToken, amount)

        return this
    }

    setChainExchange(chainExchange: ChainExchange) {
        this.chainExchange = {...chainExchange}
    }
}