import { darken, whiten, randomColor } from "@chakra-ui/theme-tools"
const Color = require('color');
// blue - purple
// const primary0 = "#C56CD6"
// const primary1 = "#3425AF"

// one blue - black
// const primary0 = "#2b74b9"; // blue
// const primary1 = "#313441"; // black

// two green -black
export const primary0 = "#0ba29d";//p0: 0ba29d - t: 25ced1
export const primary1 = "#313441"

// two red -black
// const primary0 = "#c13134"
// const primary1 = "#313441"

export const p0 = Color(primary0)
export const p1 = Color(primary1)

const Colors = {
    primaryLinear: {
        0: primary0,
        1: primary1
    },
    randomLinear: {
        0: randomColor(),
        1: randomColor()
    },
    blueLinear: {
        0: '#4d38ed',
        1: '#a2b9f7'
    },

    darkPrimaryLinear: {
        0: p0.darken(0.3).hex(),
        1: p1.darken(0.3).hex(),
        2: p0.darken(0.6).hex(),
        // "1s": p1.darken(0.6).string()
    },
    lightPrimaryLinear: {
        0: p0.lighten(0.6).hex(),
        1: p1.lighten(0.6).hex()
    }
};

export default Colors;