import { useState } from 'react';
import constate from 'constate'
import useRequest from '../libs/useRequest';
import { fromWei, flattenAndUniq } from '../libs/common';
import { useCookies } from 'react-cookie';
import useGTM from '@elgorditosalsero/react-gtm-hook'

const useDefi = () => {
    const { sendDataToGTM } = useGTM()
    const [cookies, setCookie] = useCookies(['address']);
    const [address, setAddress] = useState(cookies.address || '')
    const [yieldOptimizers, setYieldOptimizers] = useState<any[]>([])
    const [priceTokens, setPriceTokens] = useState<any[]>([])
    const [oraclePrices, setOraclePrices] = useState<{ prices?: any }>({})
    const [walletBalances, setWalletBalances] = useState<{ balances?: any[] }>({})
    const [totalWalletBalances, setTotalWalletBalances] = useState(0)
    const [totalYieldOptimizers, setTotalYieldOptimizers] = useState(0)
    const [historicalAssetTokensChartData, setHistoricalAssetTokensChartData] = useState({})

    const { isValidating, mutate: mutateGetYieldOptimizers } = useRequest(false ? {
        url: '/api/defi/getYieldOptimizers',
        method: 'POST',
        data: { address: [address] }
    } : null, {
        revalidateOnMount: false,
        revalidateOnFocus: false,
        onSuccess: (data) => {
            _updateTokens(data)

            _updateYieldOptimizers(data)
        }
    })

    const { isValidating: isValidatingGetHistoricalAssetTokens, mutate: mutateGetHistoricalAssetTokens } = useRequest(false ? {
        url: '/api/defi/getHistoricalAssetTokens',
        method: 'POST',
        data: { address }
    } : null, {
        revalidateOnMount: false,
        revalidateOnFocus: false,
        onSuccess: (historicalAssetTokens) => {
            if (historicalAssetTokens.length <= 0) {
                setHistoricalAssetTokensChartData({})

                return
            }
            setHistoricalAssetTokensChartData({
                // pointStyle: 'rectRot',
                // fill: false,
                datasets: historicalAssetTokens.map(historicalAssetToken => {
                    return {
                        // backgroundColor: randomColor(),
                        // fill: true,
                        label: historicalAssetToken.name,
                        data: Object.keys(historicalAssetToken.jsonMinutes).map((key) => {
                            debugger
                            const data = historicalAssetToken.jsonMinutes[key]
                            return { t: new Date(key + ' GMT+0'), y: data.cvlp }
                        })
                    }
                }),
                // labels: historicalAssetTokens.map(historicalAssetToken => {
                //     return Object.keys(historicalAssetToken.jsonMinutes).map((key) => {
                //         return key
                //     })
                // })[0].map(label => {
                //     return new Intl.DateTimeFormat("en-US", {hour: "numeric", minute: "numeric", hour12: false}).format(new Date(label + ' GMT+0'))

                // })
            })
        }
    })

    // const { isValidating: isValidatingPrice } = useRequest('/api/defi/getOraclePrices', {
    //     onSuccess: (data) => {
    //         setOraclePrices(data)
    //     }
    // })

    const { isValidating: isValidatingWalletBalances, mutate: mutateGetWalletBalances } = useRequest(false ? {
        url: '/api/defi/getWalletBalances',
        method: 'POST',
        data: { address }
    } : null, {
        revalidateOnMount: false,
        revalidateOnFocus: false,
        onSuccess: (data) => {
            data = _calcLastPriceWalletBalances(data)

            setWalletBalances(data)

            let total = 0
            data?.balances?.forEach((balance) => {
                total += Number.parseFloat(balance.lastPrice)
            })

            setTotalWalletBalances(total)
        }
    })

    function search(term) {
        console.log('getYieldOptimizers')
        setAddress(term)

        mutateGetYieldOptimizers()
        mutateGetWalletBalances()
        mutateGetHistoricalAssetTokens()

        setCookie('address', term)
        sendDataToGTM({ event: 'search', value: term })
    }

    function _updateTokens(dataYieldOptimizers) {
        let tokens = dataYieldOptimizers.map(currentToken => {
            return currentToken.yieldFarmings.map(yieldFarming => {
                return [yieldFarming.tokenSymbol0, yieldFarming.tokenSymbol1]
            })
        })

        tokens = flattenAndUniq(tokens)
        tokens = tokens.map(token => ({ name: token, price: 0 }))

        let newTokens: Array<{ name: string, price: string }> = []
        Object.entries(oraclePrices.prices).forEach((item) => {
            let tuple: [string, any] = item
            let [oraclePricekey, price] = tuple;

            return tokens.forEach((token) => {
                let symbol = token.name.toString().toUpperCase();

                if (oraclePricekey.toString().toUpperCase() === symbol) {
                    newTokens.push({ name: token.name, price: price.toFixed(3) });
                }
            });
        })

        setPriceTokens(newTokens)
    }

    function _updateYieldOptimizers(dataYieldOptimizers) {
        // find price0, price 1 from oracle prices
        Object.entries(oraclePrices.prices).forEach(([oraclePricekey, price]) => {
            dataYieldOptimizers.forEach((dataYieldOptimizer, ctIndex) => {
                dataYieldOptimizer.yieldFarmings.forEach((yieldFarming, yIndex) => {
                    if (yieldFarming.tokenSymbol0 && yieldFarming.tokenSymbol0.toUpperCase() === oraclePricekey.toString().toUpperCase()) {
                        dataYieldOptimizers[ctIndex].yieldFarmings[yIndex]['price0'] = price
                    }

                    if (yieldFarming.tokenSymbol1 && yieldFarming.tokenSymbol1.toUpperCase() === oraclePricekey.toString().toUpperCase()) {
                        dataYieldOptimizers[ctIndex].yieldFarmings[yIndex]['price1'] = price
                    }

                })
            })
        })

        // calc currentValue, currentToken
        let calcyieldOptimizers = dataYieldOptimizers.map((yieldOptimizer, ctIndex) => {
            yieldOptimizer.yieldFarmings.forEach((yieldFarming, yIndex) => {
                dataYieldOptimizers[ctIndex].yieldFarmings[yIndex].currentValue = 0
                dataYieldOptimizers[ctIndex].yieldFarmings[yIndex].currentToken = 0
                let isLps = yieldFarming.price0 && yieldFarming.price1 && (yieldFarming.price1 >= 0 || yieldFarming.price0 >= 0)
                let isSingleAsset = yieldFarming.price0 && (yieldFarming.price1 === undefined || yieldFarming.price1 === 0)

                if (isLps) {
                    let value0 = fromWei(yieldFarming.balance0) * yieldFarming.price0
                    let value1 = fromWei(yieldFarming.balance1) * yieldFarming.price1

                    let LPTokenPrice = (value0 + value1) / fromWei(yieldFarming.totalSupply)
                    let currentToken = fromWei(yieldFarming.balance) * fromWei(yieldFarming.pricePerFullShare)
                    let currentValue = LPTokenPrice * currentToken

                    dataYieldOptimizers[ctIndex].yieldFarmings[yIndex].currentValue = currentValue
                    dataYieldOptimizers[ctIndex].yieldFarmings[yIndex].currentToken = currentToken
                }
                if (isSingleAsset) {

                    let currentToken = fromWei(yieldFarming.balance) * fromWei(yieldFarming.pricePerFullShare)
                    let currentValue = yieldFarming.price0 * currentToken

                    dataYieldOptimizers[ctIndex].yieldFarmings[yIndex].currentValue = currentValue
                    dataYieldOptimizers[ctIndex].yieldFarmings[yIndex].currentToken = currentToken
                }
            })

            let totalCurrentValue = 0
            yieldOptimizer.yieldFarmings.forEach((yieldFarming) => {
                totalCurrentValue += yieldFarming.currentValue;
            })

            if (!yieldOptimizer.totalCurrentValueAll) {
                yieldOptimizer.totalCurrentValueAll = 0
            }
            yieldOptimizer.totalCurrentValueAll += totalCurrentValue

            return yieldOptimizer
        })

        // sort Desc
        calcyieldOptimizers.forEach((yo, i) => {
            calcyieldOptimizers[i].yieldFarmings = yo.yieldFarmings.sort((a, b) => b.currentValue - a.currentValue)
        })

        // hook
        setYieldOptimizers(calcyieldOptimizers)

        // re-calc totalCurrentValueAll
        let total = 0
        calcyieldOptimizers.forEach((c) => {
            total += c.totalCurrentValueAll
        })

        setTotalYieldOptimizers(total)
    }

    function _calcLastPriceWalletBalances(walletBalances) {
        walletBalances.balances = walletBalances?.balances?.map(walletBalance => {
            Object.entries(oraclePrices.prices).forEach((item) => {
                let tuple: [string, any] = item
                let [priceKeyOracle, price] = tuple;

                let walletBalanceSymbol = walletBalance.symbol.toString().toUpperCase()

                if (walletBalanceSymbol === priceKeyOracle.toUpperCase()) {
                    walletBalance.lastPrice = fromWei(walletBalance.balance) * price
                }
            })

            return walletBalance
        })
            .filter(walletBalance => walletBalance.lastPrice >= 0.01)
            .sort((a, b) => b.lastPrice - a.lastPrice)

        return walletBalances
    }

    return { yieldOptimizers, setYieldOptimizers, search, address, setAddress, priceTokens, isValidating, walletBalances, isValidatingWalletBalances, totalWalletBalances, totalYieldOptimizers, historicalAssetTokensChartData, isValidatingGetHistoricalAssetTokens }
}

export const [DefiProvider, useDefiContext] = constate(useDefi)