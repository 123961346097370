import React, { useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Alert,
    AlertIcon,
    Stack,
    useDisclosure
} from "@chakra-ui/react"
import { Login } from '../components/Login.component';
import { useAuthContext } from '../hooks/useAuth';


export const LoginModal = props => {
    const { isOpen, onClose } = props
    const { email, password, setEmail, setPassword, login, resetLoginForm, error, isLoading } = useAuthContext()

    useEffect(() => {
        resetLoginForm()
    }, [isOpen])

    const handleLogin = () => {
        login().then(() => {
            onClose()
        })
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Login</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Login email={email} password={password} setEmail={setEmail} setPassword={setPassword} />

                    {error ? <Stack pt={3}>
                        <Alert status="error" variant="solid">
                        <AlertIcon />
                        {error}
                    </Alert>
                    </Stack>: null}

                </ModalBody>

                <ModalFooter>
                    <Button mr={3} onClick={onClose} variant="ghost" colorScheme="gray" >Close</Button>
                    <Button isLoading={isLoading} loadingText="Submitting" variant="ghost" colorScheme="green" onClick={handleLogin}>Login</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
};
