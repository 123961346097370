import { useState} from 'react'
import constate from 'constate'

const useSearch = () => {
    const [term, setTerm] = useState('')
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [search, setSearch] = useState(null)

    return { error, setError, isLoading, setIsLoading, term, setTerm, search, setSearch }
}

export const [SearchProvider, useSearchContext] = constate(useSearch)