import { mode } from "@chakra-ui/theme-tools"

const Accordion = {
    baseStyle: (props) => ({
        button: {
            bg: mode('gray.50', 'gray.900')(props)
        }
    })
}

export default Accordion;