import {
    TokenAmount as sdkTokenAmount,
    Trade,
    WETH as _WETH,
    Fetcher,
    Pair,
    Token as sdkToken
} from '@sushiswap/sdk';
import JSBI from 'jsbi';
import { Contract, providers } from 'ethers';
import IUniswapV2Pair from '@uniswap/v2-core/build/IUniswapV2Pair.json';
import { pack, keccak256 } from '@ethersproject/solidity';
import { getCreate2Address } from '@ethersproject/address';

import { ethers } from 'ethers';
import Web3 from 'web3';

import { IPair } from '../entities/Pair';
import { Token as IToken } from '../entities/Token';
import { ChainExchange } from '../entities/ChainExchange';
import { AbiItem } from 'web3-utils'; // as AbiItem[]
import { IPairPricer, PairPricer } from '../entities/PairPricer';
// import RouterABI  from '../entities/RouterABI.json';
const RouterABI = require("../entities/RouterABI.json");


export class PairService {

    static async fetchPrice(pair: IPair, chainExchange: ChainExchange) {
        const jsonRpcProvider = getRandom(chainExchange.jsonRpcProviderUrl)
        const network = providers.getNetwork(pair.chain.id);
        const provider = new providers.JsonRpcProvider(jsonRpcProvider, network);

        const yourTokens = [
            {
                token: pair.token0.address,
                symbol: pair.token0.symbol,
                name: pair.token0.name,
                chainId: pair.token0.chainId
            },
            {
                token: pair.token1.address,
                symbol: pair.token1.symbol,
                name: pair.token1.name,
                chainId: pair.token1.chainId
            }
        ]

        const tokens = await Promise.all(yourTokens.map(pToken => {
            return Fetcher.fetchTokenData(pToken.chainId, pToken.token, provider, pToken.symbol, pToken.name);
        }))

        const [token0, token1] = tokens

        const pairs = [
            [token0, token1],
        ]
        const pairData = await Promise.all(pairs.map(pair => {
            return PairService.fetchPairData(
                pair[0],
                pair[1],
                provider,
                chainExchange
            );
        }))

        const [pair0_1] = pairData
        let amountFromInput: number = 1;
        if (pair.token0) {
            amountFromInput = pair.token0.amount || 1;
        }

        // fix feePercent
        // amountFromInput = amountFromInput + (amountFromInput * chainExchange.feePercent / 100)

        console.log('chainExchange.feePercent', chainExchange.feePercent)

        const inputAmount = new sdkTokenAmount(token0, JSBI.BigInt(amountFromInput * Math.pow(10, token0.decimals)))
        inputAmount.toSignificant()
        const result = Trade.bestTradeExactIn([pair0_1], inputAmount, token1, { maxNumResults: 1 })

        const trade = result[0]
        // const pairAmout = new Pair(new TokenAmount(token0, '0'), new TokenAmount(token1, '0'))
        // console.log('pair', pair.liquidityToken.address)

        // console.log('> output amount', trade.outputAmount.toExact(), trade.outputAmount.currency.getSymbol())

        return trade
    }

    static async fetchPriceV2(pairPricer: PairPricer) {
        const chainExchange: ChainExchange = pairPricer.chainExchange
        const jsonRpcProvider = getRandom(chainExchange.jsonRpcProviderUrl)
        const web3 = new Web3(new Web3.providers.HttpProvider(jsonRpcProvider));

        const Contract = new web3.eth.Contract(RouterABI, chainExchange.router);

        const amount = pairPricer.token0.amount || 1;

        const amountIn = JSBI.BigInt(Math.round(amount * Math.pow(10, pairPricer.token0.decimals)))

        return await Contract.methods.getAmountsOut(amountIn.toString(), [pairPricer.token0.address, pairPricer.token1.address]).call()
    }

    static fetchPairData(tokenA, tokenB, provider, chainExchange: ChainExchange) {
        try {
            const tokens = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA] // does safety checks

            let address = getCreate2Address(
                chainExchange.factoryAddress,
                keccak256(['bytes'], [pack(['address', 'address'], [tokens[0].address, tokens[1].address])]),
                chainExchange.initCodeHash
            )

            return Promise.resolve(new Contract(address, IUniswapV2Pair.abi, provider).getReserves()).then((_ref) => {
                var reserves0 = _ref[0],
                    reserves1 = _ref[1];
                var balances = tokenA.sortsBefore(tokenB) ? [reserves0, reserves1] : [reserves1, reserves0];
                return new Pair(new sdkTokenAmount(tokenA, balances[0]), new sdkTokenAmount(tokenB, balances[1]));
            });
        } catch (e) {
            return Promise.reject(e);
        }
    };

    static liquidityToken() {

    }
}

function getRandom(list: string[]) {
    return list[Math.floor((Math.random() * list.length))];
}
