const Web3 = require('web3');
const web3 = new Web3(new Web3.providers.HttpProvider("https://bsc-dataseed4.defibit.io/"));

export function unique(list) {
    return [...new Set(list)];
}

export function flattenAndUniq(list) {
    list = flatten(list).filter(t => t).map(tokenAddress => tokenAddress.toString().toLowerCase())

    return [...new Set(list)];
}

export function flatten(arr) {
    return arr.reduce(function (flat, toFlatten) {
        return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
    }, []);
}

export function bnAdd(a, b) {
    if (!a || !b) {
        return 0
    }

    if (!web3.utils.isBN(a)) {
        a = web3.utils.toBN(a)
    }
    if (!web3.utils.isBN(b)) {
        b = web3.utils.toBN(b)
    }

    return a.add(b)
}

export function bnMutiple(a, b) {
    if (!a || !b) {
        return 0
    }
    try {
        a = web3.utils.toBN(`${a}`)
    } catch (error) {
    }

    try {
        b = web3.utils.toBN(`${b}`)
    } catch (error) {

    }

    return a.mul(b).toString()
}

export function bnDiv(a, b) {
    if (!a || !b) {
        return 0
    }

    if (!web3.utils.isBN(a)) {
        a = web3.utils.toBN(a)
    }
    if (!web3.utils.isBN(b)) {
        b = web3.utils.toBN(b)
    }

    return a.div(b)
}

export function fromWei(n: any): number {
    if (!n) {
        return 0
    }

    const str = web3.utils.fromWei(n)

    return Number.parseFloat(Number.parseFloat(str).toFixed(4))
}

export function format$(value) {
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(value);
}
