import React, { useEffect, Suspense } from 'react';
import {
  ChakraProvider, SkeletonText, Container
} from '@chakra-ui/react';

import theme from "./theme";

import { BrowserRouter, Switch, Route } from "react-router-dom"

// import { Line, Doughnut } from 'react-chartjs-2';
// import { DashBoard } from './containers/Dashboard.container';
import MasterLayout from './theme/layouts/MasterLayout';
import useGTM from '@elgorditosalsero/react-gtm-hook'
const DashBoard = React.lazy(() => import('./containers/Dashboard.container'));

function App() {
  const { init, UseGTMHookProvider } = useGTM()
  useEffect(() => init({
    id: process.env.REACT_APP_GTM_ID,
  }), [])

  return (
    <ChakraProvider theme={theme}>
      <UseGTMHookProvider>
        <BrowserRouter>
          <MasterLayout>
            <Switch>
              <Route path="/">
                <Suspense fallback={<Container maxW="container.xl" mb={10}><SkeletonText isLoaded={false} noOfLines={4} spacing="4"  ></SkeletonText></Container>}>
                  <DashBoard></DashBoard>
                </Suspense>
              </Route>
            </Switch>
          </MasterLayout>
        </BrowserRouter>
      </UseGTMHookProvider>
    </ChakraProvider>
  );
}

export default App;
