// theme.js
import { extendTheme } from "@chakra-ui/react"

// Global style overrides
import styles from "./styles"

// Foundational style overrides
import borders from "./foundations/borders"

// Component style overrides
import Button from "./components/button"
import Accordion from "./components/Accordion"

import colors from "./colors"

const overrides = {
    styles: {
        global: (props) => ({
            "html, body": {
                bg: props.colorMode === "dark" ? "gray.800" : "gray.200",
                // lineHeight: "tall",
            },
            // a: {
            //     color: props.colorMode === "dark" ? "lightPrimaryLinear.1" : "purple.600",
            // },
            // button: {
            //     _hover: {
            //         color: props.colorMode === "dark" ? 'lightPrimaryLinear.1' : "purple.600",
            //     }
            // }
        }),
    },
    borders,
    components: {
        Button,
        Accordion
    },
    colors,
    shadows: {
        outline: 'none'
    }
}

export default extendTheme(overrides);