import { useState, useEffect } from 'react';
import constate from 'constate'
import useRequest from '../libs/useRequest';
import { useCookies } from 'react-cookie';
import useGTM from '@elgorditosalsero/react-gtm-hook'
import { Chain } from '../entities/Chain';
import { Token } from '../entities/Token';
import { IPair, Pair } from '../entities/Pair';
import { PairService } from '../services/pairService';
import { Trade } from '@sushiswap/sdk';
import { Exchange } from '../entities/Exchange';
import { ChainExchange } from '../entities/ChainExchange';
import { IPairPricer, PairPricer } from '../entities/PairPricer';

const useWatchList = () => {
    // const { sendDataToGTM } = useGTM()
    const [watchListCookie, setWatchListCookie] = useCookies(['watchList']);
    const [isAdding, setIsAdding] = useState<boolean>(false)

    const [watchList, setWatchList] = useState<PairPricer[]>([])
    const [chains, setChains] = useState<Chain[]>([])
    const [tokens, setTokens] = useState<Token[]>([])
    const [exchanges, setExchanges] = useState<Exchange[]>([])
    const [chainExchanges, setChainExchanges] = useState<ChainExchange[]>([])
    const [currentChain, setCurrentChain] = useState<number>(0)
    // const [oraclePrices, setOraclePrices] = useState<{ prices?: any }>({})

    useRequest({
        url: 'defiscan',
        method: 'POST',
        data: {
            "controller": "chains",
            "method": "GET",
            "params": []
        }
    }, {
        // revalidateOnMount: false,
        revalidateOnFocus: false,
        onSuccess: (data: Chain[]) => {
            setChains(data)
        }
    })

    useRequest(currentChain ? {
        url: 'defiscan',
        method: 'POST',
        data: {
            "controller": "tokens",
            "method": "GET",
            "action": "chainIds",
            "params": [currentChain]
        }
    } : null, {
        // revalidateOnMount: false,
        revalidateOnFocus: false,
        onSuccess: (tokens: Token[]) => {
            setTokens(tokens)
        }
    })

    // useRequest(currentChain ? {
    //     url: 'defiscan',
    //     method: 'POST',
    //     data: {
    //         "controller": "exchanges",
    //         "method": "GET",
    //         "action": "",
    //         "params": []
    //     }
    // } : null, {
    //     // revalidateOnMount: false,
    //     revalidateOnFocus: false,
    //     onSuccess: (exchange: Exchange[]) => {
    //         setExchanges(exchange)
    //     }
    // })

    useRequest(currentChain ? {
        url: 'defiscan',
        method: 'POST',
        data: {
            "controller": "exchanges",
            "method": "GET",
            "action": "getCodeDataByChainId",
            "params": [
                currentChain
            ]
        }
    } : null, {
        // revalidateOnMount: false,
        revalidateOnFocus: false,
        onSuccess: (chainExchange: ChainExchange[]) => {
            setChainExchanges(chainExchange)
        }
    })

    function addPair(pairPricer: PairPricer) {
        setIsAdding(true)
        setWatchList([])
        return addPairV2(pairPricer);

        // chainExchanges.map(chainExchange => {
        //     return PairService.fetchPrice(pair, chainExchange)
        //         .then((trade: Trade) => {
        //             const copyPair = new Pair(pair.chain, pair.token0, pair.token1, trade, chainExchange)

        //             setWatchList(
        //                 prevArray => prevArray.concat(copyPair)
        //                     .filter(pair => pair.trade)
        //                     .sort((a, b) => {
        //                         return parseFloat(b.trade.outputAmount.toExact()) - parseFloat(a.trade.outputAmount.toExact())
        //                     })
        //             )
        //         }).finally(() => {
        //             setIsAdding(false)
        //         }).catch(e => {
        //             console.log('e added', e)
        //         })
        // })
    }

    function addPairV2(pairObject: any) {
        setIsAdding(true)
        setWatchList([])

        Promise.all(
            chainExchanges.map(chainExchange => {
                const pairPricer = new PairPricer(pairObject.chain, pairObject.token0, pairObject.token1, chainExchange)

                return PairService.fetchPriceV2(pairPricer)
                    .then((contractPricer: any) => {
                        pairPricer.setToken0Amount(JSON.parse(JSON.stringify(contractPricer[0])))
                        pairPricer.setToken1Amount(JSON.parse(JSON.stringify(contractPricer[1])))

                        return pairPricer
                    })
                    .catch(() => { })
            })
        ).then((contractPricers: any[]) => {
            contractPricers = contractPricers
                .filter(item => item)
                .sort((a, b) => {
                    return parseFloat(b.token1.tokenAmount.toExact()) - parseFloat(a.token1.tokenAmount.toExact())
                })

            setWatchList(contractPricers)
        }).finally(() => {
            setIsAdding(false)
        }).catch((e) => {
        })
    }
    useEffect(() => {
        const priceInterval = setInterval(() => {
            if (priceInterval) {
                clearInterval(priceInterval)
            }

            watchList.map(pairPricer => {

                return PairService.fetchPriceV2(pairPricer).then((contractPricer: any) => {
                    console.log('contractPricer', contractPricer, pairPricer.chainExchange.name);

                    pairPricer.setToken0Amount(JSON.parse(JSON.stringify(contractPricer[0])))
                    pairPricer.setToken1Amount(JSON.parse(JSON.stringify(contractPricer[1])))

                    setWatchList(
                        prevArray => prevArray.map(prevPair => {
                            return prevPair.id === pairPricer.id ? pairPricer : prevPair
                        })
                            .sort((a, b) => {
                                return parseFloat(b.token1.tokenAmount.toExact()) - parseFloat(a.token1.tokenAmount.toExact())
                            })

                    )
                }).catch(console.log)
            })
        }, 5000);

        return () => {
            clearInterval(priceInterval)
        }
    }, [watchList])

    function savePairCookie(pair: Pair) {

    }

    return { watchList, chains, currentChain, setCurrentChain, tokens, addPair, isAdding, exchanges }
}

export const [WatchListProvider, useWatchListContext] = constate(useWatchList)