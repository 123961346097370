import React from "react"
import { Flex } from "@chakra-ui/react"
import Header from '../../components/Header';


export default function MasterLayout(props) {
    return (
      <Flex
        direction="column"
        justify="center"
        maxW={{ xl: "1440px"}}
        minW={{sm: "320px" }}
        m="0 auto"
        {...props}
      >
        <Header />
        {props.children}
      </Flex>
    )
  }