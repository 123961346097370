import React from "react"
import { Link } from "react-router-dom"
import {
  Text,
  Spacer, IconButton, Select, Box, Input, Image, Divider, Flex, useColorMode, useColorModeValue,
  Heading, Button, useDisclosure
} from '@chakra-ui/react';
import { AiOutlineLogin, AiOutlineUserAdd, AiOutlineMenuUnfold, AiOutlineMenuFold, AiOutlineEdit, AiOutlineSwap } from "react-icons/ai";
import { FaMoon, FaSun } from "react-icons/fa"

import { useAuthContext } from '../hooks/useAuth';

import { LoginModal } from '../containers/LoginModal.container';


const MenuItems = props => {
  const { children, isLast, to = "/", ...rest } = props

  return (
    <Text
      mb={{ base: isLast ? 0 : 8, sm: 0 }}
      mr={{ base: 0, sm: isLast ? 0 : 8 }}
      display="block"
      {...rest}
    >
      <Link to={to}>{children}</Link>
    </Text>
  )
}

const Header = props => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { authUser, logout } = useAuthContext();
  const [show, setShow] = React.useState(false)
  const toggleMenu = () => setShow(!show)
  const { toggleColorMode: toggleMode } = useColorMode()
  const text = useColorModeValue("dark", "light")
  const bgGradient = useColorModeValue("linear(to-l, #42e695, #3bb2b8)", "linear(to-l, #184e68, #57ca85)")
  const color = useColorModeValue(["gray.900", "gray.900", "gray.900", "primary.700"], "white")
  // const bg = useColorModeValue(["primary.500", "primary.500", "transparent", "transparent"], "dark")
  const bg = useColorModeValue("gray.100", "gray.900")
  const SwitchIcon = useColorModeValue(FaMoon, FaSun)

  return (
    <Flex
      display="flex"
      as="nav"
      align="center"
      // justify="start"
      wrap="wrap"
      w="full"
      p={2}
      bg={bg}
      color={color}
      shadow="lg"
      {...props}
      direction={['column', 'column', 'column', 'row']}
    >
      {/* Defi scan logo */}
      <Flex mx={2}>
        <Heading size="lg" bgGradient={"linear(to-l, primaryLinear.0, primaryLinear.1)"} color="white" rounded="lg" px={2} py={1} mb={['10px', '10px', '10px', '0px']}>DeFi Scan</Heading>
      </Flex>

      {/* Search Nav */}
      {/* <Flex align="center" w={["full", "full", "full", "500px"]} mb={['10px', '10px', '10px', '0px']} >
        <SearchNav></SearchNav>
      </Flex> */}

      {/* Mobi reponse Btn */}
      <Flex display={{ base: "flex", md: "none" }} onClick={toggleMenu} alignSelf="right" align="right">
        {show ? <AiOutlineMenuFold size="2em" /> : <AiOutlineMenuUnfold size="2em" />}
      </Flex>

      <Spacer />
      {/* Register - Login - Dark mode */}
      <Flex display={{ base: show ? "flex" : "none", md: "flex" }} flexBasis={{ base: "100%", md: "auto" }}>
        <Flex direction={["column", "row", "row", "row"]} pt={[4, 4, 0, 0]} alignItems="center">
          {/* {!!authUser ? null : <MenuItems isLast>
            <Button mr="2" size="sm" aria-label="Register" leftIcon={<AiOutlineUserAdd />}>Register</Button>
            <Button aria-label="Log in" leftIcon={<AiOutlineLogin />} size="sm" onClick={onOpen}>Log in</Button>
          </MenuItems>
          } */}
          <MenuItems isLast>
            <IconButton
              size="md"
              fontSize="lg"
              aria-label={`Switch to ${text} mode`}
              variant="ghost"
              color="current"
              ml={{ base: "0", md: "3" }}
              onClick={toggleMode}
              icon={<SwitchIcon />}
            />
          </MenuItems>
        </Flex>
      </Flex>
      <LoginModal isOpen={isOpen} onClose={onClose} />
    </Flex>
  )
}

export default Header